/* eslint-disable import/no-unresolved */
/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";

import { CType } from "../../models/Contentful/types";
import DropdownWrapper from "../../components/DropdownWrapper";
import { IAccordionProps } from "../../components/Accordion/@types";
import { IHeaderDetailProps } from "../../blades/header/@types";
import SectionWrapper from "../../components/SectionWrapper";
import { SelectFieldOption } from "../../components/FormFields/SelectField";
import Widgets from "../../blades/Widgets";
import helpers from "../../utils/helpers";
import styles from "./faqPage.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface IWidgetProps {
  widget?: CType<IHeaderDetailProps | IAccordionProps>[];
}

const FrequentlyAskAuestions: FC<IWidgetProps> = (props) => {
  const { widget = [] } = props;
  const { isMobile } = useIsMobileViewport();
  const [selectedAccordion, setSelectedAccordion] = useState<string>();
  const headerWidget = widget.find(
    (item) => item.sys?.contentType?.sys?.id === "header"
  );
  const accordionWidgets = widget.filter(
    (item) => item.sys?.contentType?.sys?.id === "accordion"
  );

  return (
    <>
      <SectionWrapper hasBodyMargin classes={styles.rootContainer}>
        {headerWidget && <Widgets widget={[headerWidget as CType<unknown>]} />}

        {accordionWidgets?.length && (
          <div className="has-text-centered">
            <DropdownWrapper
              items={accordionWidgets.map(
                (item) =>
                  ({
                    id: item.sys?.id ?? "",
                    name: item.fields.heading,
                    value: helpers.slugStringify(item.fields?.heading ?? ""),
                  } as SelectFieldOption)
              )}
              title="Jump Down to topic"
              selectedItem={
                accordionWidgets.find(
                  (item) => selectedAccordion === item.fields?.heading
                )?.fields.heading
              }
              setSelectedItem={setSelectedAccordion}
              isLinked
              showIcon
              showClearOption={false}
              isFullWidth={isMobile}
            />
          </div>
        )}
      </SectionWrapper>

      <SectionWrapper classes="pb-6">
        {accordionWidgets?.length && <Widgets widget={accordionWidgets} />}
      </SectionWrapper>
    </>
  );
};

export default FrequentlyAskAuestions;
