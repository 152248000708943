import type { GetStaticProps } from "next";
import { DefaultPage, IStaticPageProps } from "../models/Contentful/types";
import Page, { getStaticProps as sharedGetStaticProps } from "./[...slug]";

export const getStaticProps: GetStaticProps<
  IStaticPageProps<DefaultPage>
> = async (ctx) => {
  const slugRelated = sharedGetStaticProps.bind(this);
  return slugRelated(ctx);
};

export default Page;
