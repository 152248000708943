/* eslint-disable no-nested-ternary */
import { GetStaticPaths, GetStaticProps } from "next";
import React, { ReactElement } from "react";
import FrequentlyAskAuestions, { IWidgetProps } from "../Screens/FAQPage";
import buildHelpers from "../utils/buildHelpers";
import WidgetsGraphQL from "../blades/WidgetGraphQL";
import { IItemsGraphQL } from "../blades/widgetTypes";
import MainLayout from "../layout/MainLayout";
import { getAllContentEntries, getPageData } from "../models/Contentful";
import { LayoutPage } from "../types/page";
import Widgets from "../blades/Widgets";
import type { Page } from "../models/Contentful/types";
import {
  DefaultPage,
  IStaticPageProps,
  CType,
} from "../models/Contentful/types";
import WithPage from "../HOC/Page/WithPage";

const Page: LayoutPage<IStaticPageProps<DefaultPage>> = ({ page }) => {
  return (
    <div>
      <div>
        <main>
          {page.slug === "faq" ? (
            <FrequentlyAskAuestions {...(page as IWidgetProps)} />
          ) : page.slug === "testing-graphql" ? (
            <WidgetsGraphQL widget={page.widget as unknown as IItemsGraphQL} />
          ) : (
            // <div>bye</div>
            <Widgets widget={page.widget as unknown as CType<unknown>[]} />
          )}
        </main>
      </div>
    </div>
  );
};

Page.getLayout = function getLayout(
  page: ReactElement,
  props: IStaticPageProps<DefaultPage>,
) {
  return <MainLayout {...props}>{page}</MainLayout>;
};

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await getAllContentEntries({
    content_type: "pages",
  });

  const { shouldBuildClean, entrySlugs } =
    await buildHelpers.getContentEntriesSlug<Page>(
      {
        content_type: "pages",
      },
      {
        contentType: "pages",
      },
    );
  let slugs: { params: { slug: string[] } }[] = [];
  if (shouldBuildClean) {
    slugs = pages.items.map((page) => ({
      params: {
        slug: page.fields.directory
          ? [page.fields.directory, page.fields.slug]
          : [page.fields.slug],
      },
    }));
  }

  slugs =
    entrySlugs ||
    slugs.filter((slug) => {
      return (
        !slug.params.slug.includes("log-in") &&
        !slug.params.slug.includes("search") &&
        !slug.params.slug.includes("sign-up") &&
        !slug.params.slug.includes("forgot-password") &&
        !slug.params.slug.includes("reset-password") &&
        !slug.params.slug.includes("dashboard")
      );
    });
  buildHelpers.writePagesMeta(pages.items);
  return {
    paths: slugs,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<
  IStaticPageProps<DefaultPage>
> = async ({ params }) => {
  const slug = params?.slug || ["home"];
  const pageResponse = await getPageData<DefaultPage>(slug[slug.length - 1]);
  const res = { ...pageResponse, key: slug[slug.length - 1] };
  return { props: res };
};

export default WithPage(Page);
